/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useInfiniteQuery } from 'react-query';
import { toast } from 'react-toastify';
import RolesGate from '../../../../components/RolesGate';
import { DELIVERY_RECEIPTS_SRC_URL } from '../../../../config/minio';
import { rowsPerPage } from '../../../../constants/pagination';
import { Role } from '../../../../constants/role';
import { useInvoice } from '../../../../contexts/invoiceContext';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import { useInvoiceHttpService } from '../../../../services/http/invoice-http';
import formatDateWithTime from '../../../../utils/localization/formatDateWithTime';
import ReceiptModal from '../ReceiptModal';
import DeliveryStatusModal from '../DeliveryStatusModal';
import { StyledRow, StyledSubtitle, StyledTdText, StyledTitle } from './styles';
import userHasRoles from '../../../../utils/userHasRoles';
import { useAuth } from '../../../../hooks/use-auth';
import formatDateToSPTimeZone from '../../../../utils/localization/formatDateToSPTimeZone';

const InvoiceDetail = (props: any) => {
    const [userHasAccess, setUserHasAccess] = useState(false);

    const { user } = useAuth();

    const {
        selectedInvoice,
        showReceiptModal,
        setShowReceiptModal,
        showDeliveryStatusModal,
        setShowDeliveryStatusModal,
    } = useInvoice();

    const { indexUpdates, updatePortal } = useInvoiceHttpService();

    const loadInvoiceUpdates = async ({ pageParam = 0 }: any) => {
        if (!selectedInvoice.portalId) {
            return {
                data: [],
                nextPage: 0,
                pages: 0,
            };
        }

        const params = {
            skip: pageParam,
            take: rowsPerPage,
            invoiceId: selectedInvoice.id,
        };

        const response: any = await indexUpdates(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.meta.total / rowsPerPage),
        };
    };

    async function updateInvoice() {
        try {
            await updatePortal(selectedInvoice.id);
            toast.success('Atualização enviada para o portal com sucesso.');
        } catch (error) {
            toast.error('Erro ao enviar atualização.');
        }
    }

    const loadMoreRef = useRef();

    const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
        'invoice_updates',
        loadInvoiceUpdates,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        if (
            userHasRoles(user, [
                Role.ShippingCompany,
                Role.Logistics,
                Role.Administrator,
                Role.Production,
            ])
        ) {
            setUserHasAccess(true);
        }
    }, [selectedInvoice]);

    useEffect(() => {
        if (selectedInvoice.portalId) {
            refetch();
        }
    }, [selectedInvoice]);

    return (
        <React.Fragment>
            <ReceiptModal
                show={showReceiptModal}
                onHide={() => {
                    setShowReceiptModal(false);
                    refetch();
                }}
            />

            <DeliveryStatusModal
                show={showDeliveryStatusModal}
                onHide={() => {
                    setShowDeliveryStatusModal(false);
                    refetch();
                }}
            />

            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="xl"
                centered
            >
                <Modal.Header
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Detalhes do Pedido
                    </Modal.Title>
                    {userHasAccess && (
                        <Button
                            onClick={() => setShowDeliveryStatusModal(true)}
                        >
                            Incluir status de entrega
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Body className={'ml-3 w-100'}>
                    <Row className="mt-n1">
                        <Col className="mt-3" sm={8}>
                            <Row className="ml-0">
                                <StyledTitle>Atualizações</StyledTitle>
                            </Row>
                            {data?.pages &&
                                data?.pages.map((group: any) =>
                                    group?.data.map(
                                        (item: any, index2: any) => (
                                            <>
                                                <StyledRow
                                                    className="mt-4"
                                                    key={index2}
                                                >
                                                    <Col sm={12}>
                                                        <StyledSubtitle>
                                                            {item?.statusCode} -{' '}
                                                            {item?.desc}
                                                        </StyledSubtitle>
                                                        <StyledTdText className="mt-3">
                                                            {formatDateWithTime(
                                                                item?.ocurrenceDate,
                                                            )}{' '}
                                                            / Codigo da
                                                            Transportadora:{' '}
                                                            {item?.code} /{' '}
                                                            {item?.description}
                                                        </StyledTdText>
                                                    </Col>
                                                </StyledRow>
                                            </>
                                        ),
                                    ),
                                )}
                            <Row className="ml-2 mt-3">
                                <RolesGate allowedRoles={[Role.Administrator]}>
                                    <Button
                                        className="mr-5 w-50"
                                        onClick={() => {
                                            updateInvoice();
                                        }}
                                    >
                                        Atualizar Portal
                                    </Button>
                                </RolesGate>
                            </Row>
                        </Col>

                        <Col className="mt-3" sm={4}>
                            <Row>
                                <Col>
                                    <Row className="ml-n5">
                                        <StyledTitle>Dados</StyledTitle>
                                    </Row>
                                    <Row className="mt-2 ml-n4">
                                        <StyledSubtitle>
                                            Previsão de Entrega:
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className="ml-n4">
                                        <StyledTdText>
                                            {formatDateToSPTimeZone(
                                                selectedInvoice?.estimatedDeliveryDate,
                                            ) ?? 'Não Disponível'}
                                        </StyledTdText>
                                    </Row>
                                    <Row className="mt-2 ml-n4">
                                        <StyledSubtitle>
                                            Número do Pedido:
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className="ml-n4">
                                        <StyledTdText>
                                            {selectedInvoice?.portalId}
                                        </StyledTdText>
                                    </Row>
                                    <Row className="mt-2 ml-n4">
                                        <StyledSubtitle>
                                            Cliente:
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className="ml-n4">
                                        <StyledTdText>
                                            {selectedInvoice?.customerName}
                                        </StyledTdText>
                                    </Row>
                                    <Row className="mt-2 ml-n4">
                                        <StyledSubtitle>
                                            Serie da Nota Fiscal:
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className="ml-n4">
                                        <StyledTdText>
                                            {selectedInvoice?.nfSeries}
                                        </StyledTdText>
                                    </Row>
                                    <Row className="mt-2 ml-n4">
                                        <StyledSubtitle>
                                            Nota Fiscal:
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className="ml-n4">
                                        <StyledTdText>
                                            {selectedInvoice?.nfNumber}
                                        </StyledTdText>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Row className="mt-4 ml-n5">
                                        <StyledTitle>
                                            Comprov. de Entrega:
                                        </StyledTitle>
                                    </Row>
                                    <Row className="mt-3 ml-n5 mr-5">
                                        {selectedInvoice?.invoiceDeliveryReceipts &&
                                            selectedInvoice?.invoiceDeliveryReceipts.map(
                                                (receipt: any, index: any) => (
                                                    <Row key={index}>
                                                        <Col>
                                                            <li className="mt-3 ml-3">
                                                                <a
                                                                    href={`${DELIVERY_RECEIPTS_SRC_URL}/${receipt?.awsLink}.${selectedInvoice?.deliveryReceiptFormat}`}
                                                                    rel="noreferrer noopener"
                                                                    target="_blank"
                                                                >
                                                                    Comprovante
                                                                    Nº {index}
                                                                </a>
                                                            </li>
                                                        </Col>
                                                    </Row>
                                                ),
                                            )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default InvoiceDetail;
